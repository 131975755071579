import React from "react"
import ThankYouPage from "../../components/ThankYou/ThankYouPage"

const AnswerKey = (): JSX.Element => {
  return (
    <ThankYouPage subHeader="We will send you the Answer Key for UPSC 2020 soon." />
  )
}

export default AnswerKey
